<template>
  <div class="component horizontal-padding">
    <h1>Downline</h1>

    <spinner
      v-if="status === 'loading'"
      preset="large"
    />

    <template v-else-if="status === 'error'">
      <alert variant="danger">
        An error occurred loading your downline. Please check your connection
        and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadDownline"
      >
        Try Again
      </button>
    </template>

    <p v-else-if="status === 'removed'">
      You have been inactive for long enough that you were removed from the
      genealogy tree. To become active again, you need an

      <router-link :to="{ name: 'settings_subscription' }">
        active subscription</router-link> <!-- eslint-disable-line vue/multiline-html-element-content-newline, max-len -->

      or you need a customer signed up under you to have an active
      subscription.
    </p>

    <template v-else-if="status === 'loaded'">
      <p>
        <template v-if="unlockedLines === 0">
          You don't have any of your levels unlocked yet, so you won't be able
          to receive commission from anybody in your downline.
        </template>

        <template v-else>
          Congratulations, you have

          <template v-if="unlockedLines === 1">
            your first level
          </template>

          <template v-else-if="unlockedLines < 9">
            your first {{ unlockedLines }} levels
          </template>

          <template v-else>
            all 9 levels
          </template>

          unlocked!
        </template>

        <template v-if="unlockedLines < 9">
          To unlock your

          <template v-if="unlockedLines === 0">
            first level,
          </template>

          <template v-else-if="unlockedLines < 3">
            next level,
          </template>

          <template v-else-if="unlockedLines < 7">
            next two levels,
          </template>

          <template v-else>
            final two levels,
          </template>

          you will need to have somebody sign up as an IPG under you.
        </template>

        <template v-else>
          Thank you for all of your hard work!
        </template>
      </p>

      <p v-if="unlockedLines < 9">
        <router-link
          :to="{ name: 'ipg_invitations' }"
          class="btn btn-outline-primary btn-xs"
        >
          Invite a friend
        </router-link>
      </p>

      <div
        v-for="(breadcrumb, line) of downline.breadcrumbs"
        :key="`breadcrumb${line}`"
        class="breadcrumb"
      >
        <router-link :to="getBreadcrumbLink(line)">
          <profile-image
            :user="breadcrumb.user"
            bordered
          />

          <div>
            <template v-if="line">
              Level {{ line }}:
            </template>

            {{ breadcrumb.user.first_name }} {{ breadcrumb.user.last_name }}
          </div>
        </router-link>

        <div
          v-if="breadcrumb.user.username !== ''"
          class="statuses"
        >
          <div>
            Subscription status:

            <span
              :class="{
                'green-text': breadcrumb.user.subscription_status === 'active',
                'red-text': breadcrumb.user.subscription_status === 'inactive',
              }"
            >
              <template v-if="breadcrumb.user.subscription_status === 'not_subscribed'">
                Not subscribed
              </template>

              <template v-else>
                {{ capitalizeWords(breadcrumb.user.subscription_status) }}
              </template>
            </span>
          </div>

          <div>
            IPG Status:

            <span
              :class="{
                'green-text': breadcrumb.user.ipg_status === 'active',
                'red-text': breadcrumb.user.ipg_status.startsWith('inactive'),
              }"
            >
              <template v-if="breadcrumb.user.ipg_status === 'not_ipg'">
                Not an IPG
              </template>

              <template v-else-if="breadcrumb.user.ipg_status === 'active'">
                Active
              </template>

              <template v-else-if="breadcrumb.user.ipg_status === 'inactive_not_removed'">
                Inactive (pending removal from genealogy tree)
              </template>
            </span>
          </div>
        </div>

        <arrow-down-icon />
      </div>

      <div class="hexagons">
        <div
          v-for="(hexagon, index) of downline.hexagons"
          :key="`hexagon${index}`"
        >
          <router-link
            v-if="hexagon.id !== undefined"
            :to="{ name: 'ipg_downline', params: { matrixPositionId: hexagon.id } }"
          >
            <profile-image
              :user="hexagon.user"
              :size="72"
              bordered
            />
            <div>{{ hexagon.user.first_name }} {{ hexagon.user.last_name }}</div>
          </router-link>

          <template v-else>
            <profile-image
              :user="hexagon.user"
              :size="72"
              bordered
            />
            <div>{{ hexagon.user.first_name }} {{ hexagon.user.last_name }}</div>
          </template>

          <div
            v-if="hexagon.user.username !== ''"
            class="statuses"
          >
            <div>
              Subscription status:

              <span
                :class="{
                  'green-text': hexagon.user.subscription_status === 'active',
                  'red-text': hexagon.user.subscription_status === 'inactive',
                }"
              >
                <template v-if="hexagon.user.subscription_status === 'not_subscribed'">
                  Not subscribed
                </template>

                <template v-else>
                  {{ capitalizeWords(hexagon.user.subscription_status) }}
                </template>
              </span>
            </div>

            <div>
              IPG Status:

              <span
                :class="{
                  'green-text': hexagon.user.ipg_status === 'active',
                  'red-text': hexagon.user.ipg_status.startsWith('inactive'),
                }"
              >
                <template v-if="hexagon.user.ipg_status === 'not_ipg'">
                  Not an IPG
                </template>

                <template v-else-if="hexagon.user.ipg_status === 'active'">
                  Active
                </template>

                <template v-else-if="hexagon.user.ipg_status === 'inactive_not_removed'">
                  Inactive (pending removal from genealogy tree)
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { ArrowDownIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { UserWithStatusesInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    ArrowDownIcon,
    ProfileImage,
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadDownline();
    }, 0);
  },
  props: {
    matrixPositionId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'removed' | 'error',
    downline: {
      breadcrumbs: [] as Array<{
        user: UserWithStatusesInterface;
        id?: number;
      }>,
      hexagons: [] as Array<{
        user: UserWithStatusesInterface;
        id?: number;
      }>,
    },
    unlockedLines: 0,
  }),
  created() {
    this.loadDownline();
  },
  methods: {
    getBreadcrumbLink(index: number) {
      const breadcrumbLink = { name: 'ipg_downline' } as RouteLocationNormalized;

      if (index) {
        const id = this.downline.breadcrumbs[index].id as number;

        breadcrumbLink.params = {
          matrixPositionId: id.toString(),
        };
      }

      return breadcrumbLink;
    },
    async loadDownline() {
      this.status = 'loading';

      let url = 'business-portal/downline/';

      if (this.matrixPositionId) {
        url += `?id=${this.matrixPositionId}`;
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        this.unlockedLines = responseData.body.unlocked_lines;
        this.downline = responseData.body.downline;
        this.status = 'loaded';
      } else if (responseData.status === 404) {
        if (this.matrixPositionId) {
          this.$router.replace({ name: 'ipg_downline' });
        } else {
          this.status = 'removed';
        }
      } else {
        this.status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    margin: 0 auto;
    max-width: 500px;
  }

  .breadcrumb,
  .hexagons {
    text-align: center;
    font-size: 1.4rem;
    word-break: break-word;

    a {
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .breadcrumb {
    a {
      display: inline-block;
    }

    svg {
      display: block;
      margin: 1rem auto;
      width: 36px;
      height: 36px;
    }
  }

  .statuses {
    font-size: 0.68rem;
  }

  .hexagons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    a {
      display: inline-block;
    }
  }
</style>
