
import { defineComponent } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { ArrowDownIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { UserWithStatusesInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    ArrowDownIcon,
    ProfileImage,
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadDownline();
    }, 0);
  },
  props: {
    matrixPositionId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'removed' | 'error',
    downline: {
      breadcrumbs: [] as Array<{
        user: UserWithStatusesInterface;
        id?: number;
      }>,
      hexagons: [] as Array<{
        user: UserWithStatusesInterface;
        id?: number;
      }>,
    },
    unlockedLines: 0,
  }),
  created() {
    this.loadDownline();
  },
  methods: {
    getBreadcrumbLink(index: number) {
      const breadcrumbLink = { name: 'ipg_downline' } as RouteLocationNormalized;

      if (index) {
        const id = this.downline.breadcrumbs[index].id as number;

        breadcrumbLink.params = {
          matrixPositionId: id.toString(),
        };
      }

      return breadcrumbLink;
    },
    async loadDownline() {
      this.status = 'loading';

      let url = 'business-portal/downline/';

      if (this.matrixPositionId) {
        url += `?id=${this.matrixPositionId}`;
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        this.unlockedLines = responseData.body.unlocked_lines;
        this.downline = responseData.body.downline;
        this.status = 'loaded';
      } else if (responseData.status === 404) {
        if (this.matrixPositionId) {
          this.$router.replace({ name: 'ipg_downline' });
        } else {
          this.status = 'removed';
        }
      } else {
        this.status = 'error';
      }
    },
  },
});
